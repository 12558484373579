<template>
  <section class="main-content">
    <div class="container-fluid ">
      <h1 class="text-center text-white  pt-3 mt-5">
       Welcome to Flight 1963
      </h1>
      <p class="text-center text-white ">
       Your flight attendant is <span class="fan-name">{{ fanName }}</span>, Fasten your seatbelt, sit back and enjoy the flight.
      </p>
      <Events />

    
    </div>
  </section>
</template>

<script>
import "@/assets/styles/main.css";
import Events from "../components/EventCards.vue";
import axios from "axios";

export default {
  computed: {
    //Fetching the stored name from the store if null it sets default value
    fanName() {
      return this.$store.state.name || "Igiza";
    },
  },
  components: { Events },
};
</script>

<style>
.debugger {
  border: 2px solid green;
}

.fan-name{
  color: #20bdfb;
}
</style>
