import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import AgentSelection from '@/views/AgentSelection.vue'
import store from '../store/index';

function isLoggedIn() {

  const token = localStorage.getItem('token');

  if (token) {
    store.dispatch('setToken', token);
    return true;
  } else {
    return false;
  }

}


const routes = [

  {
    path: '/',
    name: 'AgentSelection',
    component: AgentSelection,
    beforeEnter: (to, from, next) => {
      next();
    },
  },

  {
    path: '/:name?',
    name: 'home',
    component: HomePage,
    props: true,
    beforeEnter: (to, from, next) => {
      const name = to.params.name;
      if (!name) {
        // If no name is provided in the URL, set the flag or condition to open the modal
        // You should implement the logic for this condition in your application
        // For now, we assume a simple flag 'shouldDisplayModal'
        next({ name: 'AgentSelection' });
      } else {
          // Name is provided, so commit it to the store and proceed
          store.commit('setName', name);
         next();
         
        }
      },
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
